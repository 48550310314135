import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./HomeHero.css";
import { useNavigate } from "react-router-dom";

const HomeHero1 = () => {
  const navigate = useNavigate();
  let data = localStorage.getItem("UserInfo");
  let userData = JSON.parse(data)
  // console.log("user info", userData.name);
  console.log("user info", data);
  const [collapsed, setCollapsed] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const [bannerData,setBannerData] = useState()
  const [loading,setLoading] = useState(false)

  const getBannerData = async ()=>{
    try {
      setLoading(true)
      const response = await fetch("https://caferoute66.com/truflix/api/v1/default/banner-session")
    const data = await response.json()
    setBannerData(data?.data)
    console.log("BANNER DATA",data)
    setLoading(false)
    } catch (error) {
      console.log("BANNER API ERROR: ",error)
      setLoading(false)
    }
  }

  useEffect(()=>{
    getBannerData()
  },[])



  return (
    <>
      <div className="hero__wrapper" style={{
        backgroundImage: bannerData ? `url(${bannerData.full_image_path})` : 'none'
      }}>
        <div className="container">
          <div className="row justify-content-center">
            <nav
              className={`navbar navbar-expand-lg navbar-black bg-transparent ${
                collapsed ? "" : "expanded"
              }`}
            >
              <div className="container-fluid">
                <a className="navbar-brand" href="/">
                  <h2 className="text-white logo-name">TRUFLIX</h2>
                </a>
                <button
                  className={` navbar-toggler ${collapsed ? "" : "collapsed"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded={!collapsed}
                  aria-label="Toggle navigation"
                  onClick={toggleNavbar}
                >
                  <span className="navbar-toggler-icon top-bar"></span>
                </button>
                <div
                  className={`collapse navbar-collapse ${
                    collapsed ? "" : "show"
                  }`}
                  id="navbarNav"
                >
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item  trueflix-nav">
                      <a
                        className="nav-link home-nav-link"
                        onClick={() => {
                          navigate("/movie-list");
                        }}
                      >
                        MOVIES
                      </a>
                    </li>

                    <li className="nav-item  trueflix-nav">
                      <a
                        className="nav-link home-nav-link"
                        onClick={() => {
                          navigate("/shows-list");
                        }}
                      >
                        TV
                      </a>
                    </li>

                    <li className="nav-item  trueflix-nav">
                      <a
                        className="nav-link home-nav-link"
                        onClick={() => {
                          navigate("/event-highlights");
                        }}
                      >
                        EVENTS
                      </a>
                    </li>
                  </ul>
                  <ul className="navbar-nav ml-auto d-lg-none">
                    {" "}
                    {/* Show only on mobile (extra small to large) */}
                    <li className="nav-item">
                      {/* <a className="nav-link " href="/account-creation">ACCOUNT</a> */}
                      <a
                        className="nav-link "
                        // href="/user-dashboard"
                        onClick={() => {
                          if (data == null) {
                            navigate("/sign-up")
                          }else{
                            navigate("/user-dashboard")
                          }
                        }}
                      >
                       {data == null? "SUBSCRIBE":"ACCOUNT"}
                      </a>
                      
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" onClick={()=>{{
                          if(data != null){
                            localStorage.removeItem("UserInfo")
                            localStorage.removeItem("UserToken")
                            navigate("/login")
                          }else if (data == null){
                            navigate("/login")
                          }
                      }}}>
                        {data == null ? "SIGN IN" : "SIGN OUT"}
                      </a>
                    </li>
                  </ul>
                  <ul className="navbar-nav d-none d-lg-flex acc-ul">
                    {" "}
                    {/* Hide on mobile (extra small to large) */}
                   <li className="nav-item">
                      <a
                        className="nav-link home-nav-link"
                        // href="/user-dashboard"
                        // onClick={notify}
                        onClick={() => {
                          if (data == null) {
                            navigate("/get-plan")
                          }else{
                            navigate("/user-dashboard")
                          }
                        }}
                      >
                        {data == null? "SUBSCRIBE":"ACCOUNT"}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link home-nav-link"
                       
                        onClick={() => {
                          if (data != null) {
                            localStorage.removeItem("UserInfo")
                            localStorage.removeItem("UserToken")

                            navigate("/login");
                          } else if (data == null) {
                            navigate("/login");
                          }
                        }}
                      >
                        {data == null ? "SIGN IN" : "SIGN OUT"}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <div className="col-lg-6 col-md-12 col-sm-12  "></div>
            <div className="col-lg-6 col-md-12 col-sm-12" style={{marginTop:"15px"}}>
              {/* <div className="box breaking-bad-text"> */}
              {bannerData && <div className="hero-info">
                <h6 className="watch-text">{bannerData?.sub_title}</h6>
                <h3 className="breaking-bad-h1">
                  {bannerData?.title_one} <br />{bannerData?.title_two}{" "}
                  
                </h3>
                <p className="hero-description-text">
                  {bannerData?.description}
                </p>
                <button
                        // onClick={() => {
                        //   window.open(bannerData?.link);
                        // }}
                        
                        className="btn pass-btn"
                        style={{width:"50%",marginTop:"5px"}}
                      >
                        CHECK IT OUT!
                      </button>
              </div>}
              {/* </div> */}
            </div>
          </div>
          <div
            className="hero-modal"
            style={{ display: showModal ? "flex" : "none" }}
          >
            <div className="modal-logo-container">
              <h2 className="text-white">TRUFLIX</h2>
              <p style={{ color: "white" }} onClick={() => closeModal()}>
                x
              </p>
            </div>
            <div className="modal-text-container">
              <p>To continue please Login or SignUp to Truflix</p>
            </div>
            <div className="modal-button-container">
              <button onClick={()=>navigate("/login")} className="btn btn-light text-center suscribe-btn">
                Continue
              </button>
            </div>
            {/* <h4>Please create an account to continue</h4> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeHero1;

// 'useclient'
// import React, { useState, useEffect } from 'react';
// import Header from '../../components/HeaderPricing/Header';
// // import StreamingServices from './StreamingServices';

// import NetflixLogo from "../../Assets/Images/icons/NETFLIX.svg";
// import AmazonPrimeLogo from "../../Assets/Images/icons/PRIME.svg";
// import HuluLogo from "../../Assets/Images/icons/HULU.svg";
// import Grayboxlogo from "../../Assets/Images/icons/gray-box.svg";
// import { useLocation } from 'react-router-dom';

// import { ReactComponent as YouTubeIcon }  from "../../Assets/Images/icons/icon-youtube.svg";
// import YouTube from 'react-youtube';
// import MovieHighLight from "../../components/MovieHighLight";
// import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'; // Import Dialog components from Material-UI
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import { BASEURL } from '../../API/constants';
// // import './MovieDetails.css'; // Ensure CSS contains necessary modifications for score circle

// const ShowsDetailsPage = () => {
//   const [movieData, setMovieData] = useState();
//   let state = {}
//   const location = useLocation()
//   console.log("location.state",location.state)
//   const services = [
//     {
//       number: "1",
//       logo: NetflixLogo,
//       platform: "NETFLIX",
//       subTitle: "FREE TO SUBSCRIBERS",
//       description: "Available free to everyone with access to Netflix."
//     },
//     {
//       number: "2",
//       logo: AmazonPrimeLogo,
//       platform: "AMAZON PRIME",
//       subTitle: "FREE TO SUBSCRIBERS",
//       description: "Available free to everyone with access to Amazon Prime."
//     },
//     {
//       number: "3",
//       logo: HuluLogo,
//       platform: "HULU",
//       subTitle: "FREE TO SUBSCRIBERS",
//       description: "Available free to everyone with access to Hulu."
//     },

//     {
//       number: "4",
//       logo: Grayboxlogo,
//       platform: "FREE STREAMING",
//       subTitle: "NOT YET AVAILABLE",
//       description: "Available free to everyone with access to Netflx."
//     }

//   ];
//   // const reviewData = [
//   //   {
//   //     //   imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/0632527b2e47586343c1ec53a1839c7320bc014d24d35b22cfa176fb7a4aa62d?apiKey=74b2fddf0d83436fad78ac66773acda0&",
//   //     score: "8.5",
//   //     source: "IMBD",
//   //     className: 'green',
//   //   },
//   //   {
//   //       // imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/27129446eb8bbc04349a2311f3634513c5d912817c601f32dbadfba2d0d9aa66?apiKey=74b2fddf0d83436fad78ac66773acda0&",
//   //     score: "23%",
//   //     source: "IMBD",
//   //     className: 'red',
//   //   },
//   //   {
//   //     //   imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4ba07b6164c4db6be5fc802a99e60f7681b7f1ab96fe9e5b1d677008398d85dc?apiKey=74b2fddf0d83436fad78ac66773acda0&",
//   //     score: "7.5",
//   //     source: "GOOGLE",
//   //     className: 'yellow',
//   //   }
//   // ];
//   const getScoreClass = (score) => {
//     const numericScore = parseFloat(score);
//     if (numericScore >= 7.5) {
//       return 'green';
//     } else if (numericScore >= 5) {
//       return 'yellow';
//     } else {
//       return 'red';
//     }
//   };

//   useEffect(() => {

//     const fetchMovieData = async () => {
//       try {
//         const response = await fetch(`${BASEURL}/entertainment/shows/${location.state.movie_id}`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }
//         const data = await response.json();
//         setMovieData(data.data);
//         // console.log("movie data",data.data.videos[0].url)
//       } catch (error) {
//         console.error('Error fetching movie data:', error);
//       }
//     };

//     fetchMovieData();
//   }, [location.state.movie_id]);

//   console.log(typeof movieData)
//   console.log("Movie Data",movieData)
//   const [open, setOpen] = useState(false);
//   const [videoId, setVideoId] = useState('');

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // const handleOpen = (url) => {
//   //   console.log("url",url)
//   //   const parts = url.split('=');
//   //   let id = parts[1]
//   //   console.log("id",id)

//   //   setVideoId(id);
//   //   setOpen(true);
//   // };

//   const handleOpen = (url) => {
//     const videoId = new URL(url).searchParams.get('v'); // Extract video ID from URL
//     console.log("videoId", videoId);
//     setVideoId(videoId);
//     setOpen(true);
//   };

//   // console.log('movieData', id)

//   return (
//     <>
//       <Header />
//       <div className="container mt-5 pt-5">
//         <div className="row justify-content-center">
//           {movieData && (
//             <>
//               <div className="col-md-4">
//                 <img src={movieData.poster_image} className="img-fluid rounded main-img" alt={movieData.title} />
//                 <div className="text-center my-3 p-2 bg-dark text-white">{movieData.rated}</div>
//               </div>
//               <div className="col-md-8">
//                 <div className="imdb-content">
//                   <h1 className="text-white">{movieData.title}</h1>
//                   <h2 className="text-white">{movieData.year}</h2>
//                   <p className="text-white">{movieData.description}</p>
//                   <h3>REVIEWS</h3>
//                   <div className="row">
//                     {movieData.platform.map((platform, index) => (
//                       <div className="col-lg-3 mb-4" key={index}>
//                         <div className="card review-card">
//                           <div className={`score-circle ${getScoreClass(platform.score)}`}>{platform.score}</div>
//                           <div className="card-body">
//                             <p className="card-text text-center rating">{platform.name}</p>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                   </div>

//                 </div>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//       // Render StreamingServices component only when movieData is available
//       {/* <StreamingServices
//         data={location.state.movie_data}
//         // id={location.state.movie_id}
//       /> */}

// {/*  */}
// <div className="container mt-5">

// <div className="card StreamingServiceItem mb-3">
// {/* { movieData && movieData.watch.wp_one?.map((movie, index) => (
//   <div className="row g-0" key={index}>
//     <div className="col-md-5 d-flex align-items-center justify-content-start">
//       <div className="p-3 text-center">
//         <div className="display-1 fw-bold text-secondary">{index + 1}</div>
//       </div>
//       <div className="p-3 text-center">
//         <div className="movies-icons">
//           <img src={services[index].logo} alt="img" className="img-fluid platform-icon" />
//           <h5 className="card-title text-white platform-name">{movie.master.title}</h5>
//         </div>
//       </div>
//     </div>
//     <div className="col-md-7">
//       <div className="card-body text-white">
//         <p className="card-text"><small>{movie.someProperty}</small></p>
//         <p className="card-text">{movie.description}</p>
//       </div>
//     </div>
//   </div>
// ))} */}
// {movieData?.watch && Object.keys(movieData.watch).map((property, propertyIndex) => (
//   movieData.watch[property].map((movie, index) =>  (
//     <div className="row g-0" key={index}>
//       <div className="col-md-5 d-flex align-items-center justify-content-start">
//         <div className="p-3 text-center">
//           <div className="display-1 fw-bold text-secondary">{propertyIndex + 1}</div>
//         </div>
//         <div className="p-3 text-center">
//           <div className="movies-icons">

//             <img src={services[index].logo} alt="img" className="img-fluid platform-icon" />
//             <h5 className="card-title text-white platform-name">{movie.master.title}</h5>
//           </div>
//         </div>
//       </div>
//       <div className="col-md-7">
//         <div className="card-body text-white">
//           {/* Update with relevant properties */}
//           <p className="card-text">FREE TO SUBSCRIBERS</p>
//           <p className="card-text">Available free to everyone with access to {movie.master.title}.</p>
//         </div>
//       </div>
//     </div>
//   ))
// ))}

//     </div>

//     {movieData && (
//       <div className="StyledContainer mt-5">
//         <div className="row justify-content-center">
//           <h2 className="text-white mb-5 ml-2 pl-5 RELATED-VIDEOS">RELATED VIDEOS</h2>
//           <div className="col-md-4 col-lg-4">
//             <div className="card card-yt" onClick={() => handleOpen(movieData?.videos[0]?.url)}>
//               <div className="thumbnail-container">
//                 <img className="thumbnail-image" src={movieData.poster_image} alt={movieData.title} /> {/* Replace video_thumbnail_url with actual URL */}
//                 <div className="play-btn-overlay">

//                   <YouTubeIcon className="yt-btn"/>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4 col-lg-4">
//             <div className="card card-yt" onClick={() => handleOpen(movieData?.videos[0]?.url)}>
//               <div className="thumbnail-container">
//                 <img className="thumbnail-image" src={movieData.poster_image} alt={movieData.title}  /> {/* Replace video_thumbnail_url with actual URL */}
//                 <div className="play-btn-overlay">

//                 <YouTubeIcon className="yt-btn"/>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-md-4 col-lg-4">
//             </div>
//         </div>

//         {/* Dialog for displaying YouTube video */}
//         <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" className="custom-dialog">
//   <IconButton className="close-btn" onClick={handleClose}>
//     <CloseIcon />
//   </IconButton>
//   <DialogContent className="custom-dialog-content">
//     <YouTube
//       videoId={videoId}
//       opts={{
//         playerVars: {
//           controls: 0,
//           showinfo: 0,
//           modestbranding: 1,
//         },
//       }}
//     />
//   </DialogContent>
// </Dialog>

//       </div>
//       )}
//       <div className="movies-slider-container" >
//         <h2 className="text-white">POPULAR MOVIES</h2>
//         <MovieHighLight className="movies-slider" showHeader={false} />
//       </div>
//       <div className="movies-slider-container mt-0" >
//         <h2 className="text-white" >POPULAR TV SHOWS</h2>
//         <MovieHighLight className="movies-slider" showHeader={false} />
//       </div>

//     </div>

//     </>
//   );

// };

// export default ShowsDetailsPage;

"useclient";
import React, { useState, useEffect } from "react";
import Header from "../../components/HeaderPricing/Header";
// import StreamingServices from "./StreamingServices";
// import { useLocation } from 'react-router-dom';

import NetflixLogo from "../../Assets/Images/icons/NETFLIX.svg";
import AmazonPrimeLogo from "../../Assets/Images/icons/PRIME.svg";
import HuluLogo from "../../Assets/Images/icons/HULU.svg";
import Grayboxlogo from "../../Assets/Images/icons/gray-box.svg";
import { useLocation } from "react-router-dom";

import { ReactComponent as YouTubeIcon } from "../../Assets/Images/icons/icon-youtube.svg";
import YouTube from "react-youtube";
import MovieHighLight from "../../components/MovieHighLight";
import Good from "../../Assets/Images/images/good.jpg";
import Bad from "../../Assets/Images/images/bad.jpg";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  colors,
} from "@mui/material"; // Import Dialog components from Material-UI
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { BASEURL } from "../../API/constants";
// import './MovieDetails.css'; // Ensure CSS contains necessary modifications for score circle
import { ReactComponent as NetflixIcon } from "../../Assets/icons/Netflix.svg";
import { ReactComponent as DiscoveryIcon } from "../../Assets/icons/discovery.svg";
import { ReactComponent as Bbc } from "../../Assets/icons/bbc.svg";
import { ReactComponent as Espn } from "../../Assets/icons/espn.svg";
import DiscoveryLogo from "../../Assets/icons/discovery.svg";
import BbcLogo from "../../Assets/icons/bbc.svg";
import EspnLogo from "../../Assets/icons/espn.svg";
import NetflixLogoNew from "../../Assets/icons/Netflix.svg";
import ShowHighLight from "../../components/ShowsHighLight";
import Loader from "react-js-loader";

const ShowsDetailPage = () => {
  const [movieData, setMovieData] = useState();
  const [bgColor, setBgColor] = useState();
  const [title, setTitle] = useState();
  const [subTitle, setSubTitle] = useState();
  const [loading, setLoading] = useState(false);
  const [triflixScore, setTruflixScore] = useState();
  let state = {};
  const location = useLocation();
  console.log("location.state", location.state);
  const services = [
    {
      number: "1",
      logo: NetflixLogo,
      platform: "NETFLIX",
      subTitle: "FREE TO SUBSCRIBERS",
      description: "Available free to everyone with access to Netflix.",
    },
    {
      number: "2",
      logo: AmazonPrimeLogo,
      platform: "AMAZON PRIME",
      subTitle: "FREE TO SUBSCRIBERS",
      description: "Available free to everyone with access to Amazon Prime.",
    },
    {
      number: "3",
      logo: HuluLogo,
      platform: "HULU",
      subTitle: "FREE TO SUBSCRIBERS",
      description: "Available free to everyone with access to Hulu.",
    },

    {
      number: "4",
      logo: Grayboxlogo,
      platform: "FREE STREAMING",
      subTitle: "NOT YET AVAILABLE",
      description: "Available free to everyone with access to Netflx.",
    },
  ];
  // const reviewData = [
  //   {
  //     //   imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/0632527b2e47586343c1ec53a1839c7320bc014d24d35b22cfa176fb7a4aa62d?apiKey=74b2fddf0d83436fad78ac66773acda0&",
  //     score: "8.5",
  //     source: "IMBD",
  //     className: 'green',
  //   },
  //   {
  //       // imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/27129446eb8bbc04349a2311f3634513c5d912817c601f32dbadfba2d0d9aa66?apiKey=74b2fddf0d83436fad78ac66773acda0&",
  //     score: "23%",
  //     source: "IMBD",
  //     className: 'red',
  //   },
  //   {
  //     //   imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4ba07b6164c4db6be5fc802a99e60f7681b7f1ab96fe9e5b1d677008398d85dc?apiKey=74b2fddf0d83436fad78ac66773acda0&",
  //     score: "7.5",
  //     source: "GOOGLE",
  //     className: 'yellow',
  //   }
  // ];
  const getScoreClass = (score) => {
    const numericScore = parseFloat(score);
    if (numericScore >= 7.5) {
      return "green";
    } else if (numericScore >= 5) {
      return "yellow";
    } else {
      return "red";
    }
  };

  useEffect(() => {
    const fetchMovieData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${BASEURL}/entertainment/shows/${location.state.movie_id}`
        );
        console.log(
          "LOCATION",
          `${BASEURL}/entertainment/shows/${location.state.movie_id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setMovieData(data.data);
        setTruflixScore(data.data?.platform[3]?.score);
        let score = data.data?.platform[3]?.score;
        if(score >= 0 && score <= 1){
          setBgColor("#910113");
          setTitle("REVIEWERS ARE TRAUMATIZED.");
          setSubTitle("Many reviewers ba e quit after reviewing this.");
          setLoading(false);
        }
        else if (score >= 1 && score <= 1.9) {
          setBgColor("#C3001B");
          setTitle("JESUS CANT SAVE THIS.");
          setSubTitle("He says watchhing this was the worst pain he ever felt.");
          setLoading(false);
        }
        else if (score >= 2 && score <= 2.9) {
          setBgColor("#C3001B");
          setTitle("CONFIRMED SUCKFEST.");
          setSubTitle("Just don't do it.");
          setLoading(false);
        }
        else if (score >= 3 && score <= 3.9) {
          setBgColor("#FF3A5F");
          setTitle("NOPE.");
          setSubTitle("This sucks. Even if you like it, it still sucks.");
          setLoading(false);
        } else if (score >= 4.0 && score <= 4.9) {
          setBgColor("#FF617F");
          setTitle("YOU HAVE BETTER THINGS TO DO.");
          setSubTitle("Like watching paint dry. That might be more fun.");
          setLoading(false);
        } else if (score >= 5.0 && score <= 5.9) {
          setBgColor("#FFF95E");
          setTitle("TRY TO FIND SOMETHING BETTER.");
          setSubTitle("So many other things to watch, why watch this?");
          setLoading(false);
        } else if (score >= 6.0 && score <= 6.9) {
          setBgColor("#E99358");
          setTitle("TRY FOR TEN MINUTES");
          setSubTitle("You might like it. Or you might hate it.");
          setLoading(false);
        } else if (score >= 7.0 && score <= 7.9) {
          setBgColor("#29AEE5");
          setTitle("GIVE IT A SHOT!");
          setSubTitle("Most people liked it. ");
          setLoading(false);
        } else if (score >= 8.0 && score <= 8.9) {
          setBgColor("#5EFF58");
          setTitle("DAMN GOOD! ");
          setSubTitle("Make sure you watch this.");
          setLoading(false);
        } else if (score >= 9.0 && score <= 9.9) {
          setBgColor("#C2AA45");
          setTitle("FRICKIN’ GOLD!");
          setSubTitle("Stop whatever you’re doing and watch this now!");
          setLoading(false);
        } else {
          return "Out of range";
          setLoading(false);
        }
        console.log("movie data", data.data);
      } catch (error) {
        console.error("Error fetching movie data:", error);
        setLoading(false);
      }
    };

    fetchMovieData();
  }, [location.state.movie_id]);

  // console.log(typeof movieData);
  console.log("Movie Data", movieData);
  const [open, setOpen] = useState(false);
  const [videoId, setVideoId] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (url) => {
    console.log("url", url);
    const parts = url.split("=");
    let id = parts[1];
    console.log("id", id);

    setVideoId(id);
    setOpen(true);
  };

  // const handleOpen = (url) => {
  //   const videoId = new URL(url).searchParams.get('v'); // Extract video ID from URL
  //   console.log("videoId", videoId);
  //   setVideoId(videoId);
  //   setOpen(true);
  // };

  // console.log('movieData', id)

  return (
    <>
      <Header />
      {loading ? (
        <Loader
          type="spinner-default"
          bgColor={"white"}
          color={"black"}
          title={""}
          size={50}
        />
      ) : (
        <div className="container mt-5 pt-5">
          <div className="row justify-content-center">
            {movieData && (
              <>
                <div className="col-md-3">
                  <img
                    src={movieData.poster_image}
                    className="img-fluid rounded main-img"
                    alt={movieData.title}
                  />
                  <div className="text-center my-3 p-2 bg-dark text-white">
                    {movieData.rated}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="imdb-content">
                    <h1 className="text-white">{movieData.title}</h1>
                    <h5 className="text-white" style={{marginTop:"18px",marginBottom:"18px" }}>{movieData.year}</h5>
                    <div className="container p-0" style={{display:"flex",marginTop:"15px",marginBottom:"15px"}}>
                    {movieData?.genres.map((i,index)=>(
                      <h5 className="text-white" key={index}>{i?.title}{index < movieData.genres.length - 1 && "  | "}&nbsp;</h5>
                    ))}
                    </div>
                    <p className="text-white">{movieData.description}</p>
                   
                    {/* <h3>REVIEWS</h3> */}
                    <div className="row">
                      {/* {movieData.platform.map((platform, index) => (
                      <div className="col-lg-3 mb-4" key={index}>
                        <div className="card review-card">
                          <div
                            className={`score-circle ${getScoreClass(
                              platform.score
                            )}`}
                          >
                            {platform.score}
                          </div>
                          <div className="card-body">
                            <p className="card-text text-center rating">
                              {platform.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))} */}
                      <div
                        className="col-lg-12 mb-4"
                        style={{ marginTop: "15px" }}
                      >
                        <h4 style={{ color: "white" }}>TRUFLIX SCORE</h4>
                        <div
                          style={{
                            width: "100%",
                            height: "80%",
                            // backgroundColor: "white",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            className="score-circle"
                            style={{
                              backgroundColor: bgColor,
                              marginTop: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h5
                              style={{
                                color: "black",
                                fontSize: "30px",
                                // lineHeight: "10px",
                                marginTop:"8px"
                              }}
                            >
                              {triflixScore}
                            </h5>
                          </div>
                          <div
                            style={{
                              width: "65%",
                              height: "100%",
                              // backgroundColor: "red",
                              marginLeft: "40px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <h5 style={{ color: bgColor }}>{title}</h5>
                            <p style={{ color: "white" }}>{subTitle}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {/* <StreamingServices
        data={location.state.movie_data}
        // id={location.state.movie_id}
      />  */}

      {/*  */}
      {loading ? (
        <Loader
          type="spinner-default"
          bgColor={"white"}
          color={"black"}
          title={""}
          size={50}
        />
      ) : (
        <div className="container" style={{marginTop:"130px"}}>
          {/* <div className="card StreamingServiceItem mb-3">
          {/* { movieData && movieData.watch.wp_one?.map((movie, index) => (
  <div className="row g-0" key={index}>
    <div className="col-md-5 d-flex align-items-center justify-content-start">
      <div className="p-3 text-center">
        <div className="display-1 fw-bold text-secondary">{index + 1}</div>
      </div>
      <div className="p-3 text-center">
        <div className="movies-icons">
          <img src={services[index].logo} alt="img" className="img-fluid platform-icon" />
          <h5 className="card-title text-white platform-name">{movie.master.title}</h5>
        </div>
      </div>
    </div>
    <div className="col-md-7">
      <div className="card-body text-white">
        <p className="card-text"><small>{movie.someProperty}</small></p> 
        <p className="card-text">{movie.description}</p>
      </div>
    </div>
  </div>
))} */}
          {/* {movieData?.watch &&
            Object.keys(movieData.watch).map((property, propertyIndex) =>
              movieData.watch[property].map((movie, index) => (
                <div className="row g-0" key={index}>
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <div className="p-3 text-center">
                      <div className="display-1 fw-bold text-secondary">
                        {propertyIndex + 1}
                      </div>
                    </div>
                    <div className="p-3 text-center">
                      <div className="movies-icons">
                        <img
                          src={movie.master.image}
                          alt="img"
                          className="img-fluid platform-icon"
                        />
                        <h5 className="card-title text-white platform-name">
                          {movie.master.title}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="card-body text-white">
                      {/* Update with relevant properties */}
          {/* <p className="card-text">FREE TO SUBSCRIBERS</p>
                      <p className="card-text">
                        Available free to everyone with access to{" "}
                        {movie.master.title}.
                      </p>
                    </div>
                  </div>
                </div>
              )) */}
          {/* )}  */}
          {/* </div> */}
          <div
            className="container-fluid flex"
            // style={{ backgroundColor: "blue" }}
          >
            <h3 style={{ color: "white",marginLeft:"30px" }}>WHERE TO WATCH THIS SHOW</h3>
            <div style={{ width: "100%", height: "auto", display: "flex",marginBottom:"50px" }}>
              <div className="channels-1 row">
                {movieData?.watch?.wp_one?.[0] && (
                  <div
                    className="col-md-6 service"
                    style={{ marginBottom: "10px" }}
                  >
                    <img
                      src={movieData?.watch?.wp_one[0]?.master?.image}
                      style={{
                        marginLeft: "10px",
                        width: "40%",
                        height: "80%",
                        objectFit: "contain",
                      }}
                    />
                    <div className="service-info">
                      <h4 style={{ marginLeft: "25px",color:"white" }}>
                        {movieData?.watch?.wp_one[0]?.master?.title}
                      </h4>
                      <p style={{ marginLeft: "25px", fontWeight: "600" }}>
                        FREE TO SUBSCRIBERS
                      </p>
                      <p
                        style={{
                          marginLeft: "25px",
                          lineHeight: "20px",
                          fontSize: "14px",
                        }}
                      >
                        Available free to everyone with access to{" "}
                        {movieData?.watch?.wp_one[0]?.master?.title}.
                      </p>
                    </div>
                  </div>
                )}

                {movieData?.watch?.wp_three?.[0] && (
                  <div
                    className="col-md-6 service"
                    style={{ marginBottom: "10px" }}
                  >
                    <img
                      src={movieData?.watch?.wp_three[0]?.master?.image}
                      style={{
                        marginLeft: "10px",
                        width: "40%",
                        height: "80%",
                        objectFit: "contain",
                      }}
                    />
                    <div className="service-info">
                      <h4 style={{ marginLeft: "25px",color:"white" }}>
                        {movieData?.watch?.wp_three[0]?.master?.title}
                      </h4>
                      <p style={{ marginLeft: "25px", fontWeight: "600" }}>
                        FREE TO SUBSCRIBERS
                      </p>
                      <p
                        style={{
                          marginLeft: "25px",
                          lineHeight: "20px",
                          fontSize: "14px",
                        }}
                      >
                        Available free to everyone with access to{" "}
                        {movieData?.watch?.wp_three[0]?.master?.title}.
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="channels-1 row">
                {movieData?.watch?.wp_two?.[0] && (
                  <div
                    className="col-md-6 service"
                    style={{ marginBottom: "10px" }}
                  >
                    <img
                      src={movieData?.watch?.wp_two[0]?.master?.image}
                      style={{
                        marginLeft: "10px",
                        width: "40%",
                        height: "80%",
                        objectFit: "contain",
                      }}
                    />
                    <div className="service-info">
                      <h4 style={{ marginLeft: "25px",color:"white" }}>
                        {movieData?.watch?.wp_two[0]?.master?.title}
                      </h4>
                      <p style={{ marginLeft: "25px", fontWeight: "600" }}>
                        FREE TO SUBSCRIBERS
                      </p>
                      <p
                        style={{
                          marginLeft: "25px",
                          lineHeight: "20px",
                          fontSize: "14px",
                        }}
                      >
                        Available free to everyone with access to{" "}
                        {movieData?.watch?.wp_two[0]?.master?.title}.
                      </p>
                    </div>
                  </div>
                )}

                {movieData?.watch?.free_option?.[0] && (
                  <div
                    className="col-md-6 service"
                    style={{ marginBottom: "10px" }}
                  >
                    <img
                      src={movieData?.watch?.free_option[0]?.master?.image}
                      style={{
                        marginLeft: "10px",
                        width: "40%",
                        height: "80%",
                        objectFit: "contain",
                      }}
                    />
                    <div className="service-info">
                      <h4 style={{ marginLeft: "25px",color:"white" }}>
                        {movieData?.watch?.free_option[0]?.master?.title}
                      </h4>
                      <p style={{ marginLeft: "25px", fontWeight: "600" }}>
                        FREE TO SUBSCRIBERS
                      </p>
                      <p
                        style={{
                          marginLeft: "25px",
                          lineHeight: "20px",
                          fontSize: "14px",
                        }}
                      >
                        Available free to everyone with access to{" "}
                        {movieData?.watch?.free_option[0]?.master?.title}.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* reviews container */}
          <div>
            {movieData?.pro?.length > 0 && movieData?.con?.length > 0 && (
              <div className="container-fluid" style={{marginTop:"130px"}}>
                <div className="reviews-container mt-8">
                  {/* good */}
                  <div className="review-container container">
                    <h3 style={{color:"#fff",marginLeft:"15px"}}>WHAT YOU’LL LIKE</h3>
                    <div
                      className="mt-4 container"
                      style={{ marginLeft:"12px" }}
                    >
                      <img
                        src={Good}
                        style={{
                          width: "18%",
                          height: "18%",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                    {movieData.pro.map((i, index) => (
                      <div className="review-box" style={{marginLeft:"12px"}} key={index}>
                        <div className="mt-4">
                        
                          <h4 style={{ color: "white" }}>{i?.title}</h4>
                        </div>
                        <div className="mt-4">
                          <p
                            style={{
                              color: "white",
                              fontSize: "14px",
                              paddingRight: "24px",
                            }}
                          >
                            {i?.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* bad */}
                  <div className="review-container container">
                    <h3 style={{color:"#fff"}}>WHAT YOU MIGHT NOT</h3>
                    <div
                      className="mt-4 container"
                      // style={{ backgroundColor: "blue" }}
                    >
                      <img
                        src={Bad}
                        style={{
                          width: "18%",
                          height: "18%",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                    {movieData.con.map((i, index) => (
                      <div className="review-box" key={index}>
                        <div className="mt-4">
                          <h4 style={{ color: "white" }}>{i?.title}</h4>
                        </div>
                        <div className="mt-4">
                          <p
                            style={{
                              color: "white",
                              fontSize: "14px",
                              paddingRight: "24px",
                            }}
                          >
                            {i?.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* <div className="container-fluid">
        <div className="reviews-container">
          <div className="review-container">
            <h3>WHAT YOU’LL LIKE</h3>
          </div>
          <div className="review-container" style={{backgroundColor:"blue"}}>
            <h3>WHAT YOU MIGHT NOT</h3>
          </div>
        </div>
        </div> */}
          {/* Related Videos */}
          {movieData && (
            <div className="StyledContainer mt-5">
              <div className="row" style={{marginLeft:"40px"}}>
                <h3 className="text-white mb-5 ml-2 pl-5 RELATED-VIDEOS" >
                  RELATED VIDEOS
                </h3>
                {movieData?.videos?.map((i, index) => (
                  <div className="col-md-4 col-lg-4" key={index} style={{cursor:"pointer"}}>
                    <div
                      className="card card-yt"
                      onClick={() => handleOpen(i?.url)}
                    >
                      <div className="thumbnail-container">
                        <img
                          className="thumbnail-image"
                          src={i?.image}
                          alt={i?.title}
                        />{" "}
                        {/* Replace video_thumbnail_url with actual URL */}
                        <div className="play-btn-overlay">
                          <YouTubeIcon className="yt-btn" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Dialog for displaying YouTube video */}
              <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                className="custom-dialog"
              >
                <IconButton className="close-btn" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
                <DialogContent className="custom-dialog-content">
                  <YouTube
                    videoId={videoId}
                    opts={{
                      playerVars: {
                        controls: 0,
                        showinfo: 0,
                        modestbranding: 1,
                      },
                    }}
                  />
                </DialogContent>
              </Dialog>
            </div>
          )}
          <div style={{width:"100%",height:"100px"}}/>
          {/* <div className="movies-slider-container"> */}
            <h3 className="text-white" style={{marginLeft:"55px"}}>POPULAR MOVIES</h3>
            <MovieHighLight className="movies-slider" showHeader={false} />
          {/* </div> */}
          {/* <div className="movies-slider-container mt-0"> */}
          <div style={{width:"100%",height:"100px"}}/>
            <h3 className="text-white" style={{marginLeft:"55px"}}>POPULAR TV SHOWS</h3>
            <ShowHighLight className="movies-slider" showHeader={false} />
          {/* </div> */}
        </div>
      )}
      <footer className="footer">
        <div
          className="container"
          style={{
            borderTop: "0.5px solid gray",
            backgroundColor: "white",
            marginBottom: "60px",
          }}
        ></div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="footer-logo tex-left">
                <img
                  src="/images/TRUFLIX-logo.svg"
                  alt="img"
                  className="logo-img"
                />
                <p className="text-white mt-2">
                  Truflix © 2024. All Rights Reserved..
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-dev">
                <nav className="footer-nav footer-link-sec ">
                  <ul className="footer-links">
                    <a
                      href="#"
                      className="footer-link p-0"
                      style={{ lineHeight: "5px" }}
                    >
                      <li>TERMS AND CONDITIONS</li>
                    </a>
                    <a
                      href="#"
                      className="footer-link"
                      style={{ lineHeight: "5px" }}
                    >
                      <li>DISCLAIMER</li>
                    </a>
                    <a
                      href="#"
                      className="footer-link"
                      style={{ lineHeight: "5px" }}
                    >
                      <li>TRUFLIX PRIVATE POLICY</li>
                    </a>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <style jsx>{`
          .main-container {
            width: 100%;
            background-color: #000;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 5vw 10vw; /* Using vw units for padding */
            font-family: Montserrat, sans-serif;
          }
          // .site-header {
          //   width: 100%;
          //   text-align:center
          // }
          // .site-header h1 {
          //   text-align:center;
          //   padding-top: 3em !important;

          // }
          .guide-description {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

          // @media screen and (max-width: 1440px) {
          //   .site-header h1 {
          //     padding-top: 5em !important;
          //     padding-left: 1rem !important;
          //   }
          // }
          // @media (max-width: 991px) {
          //   .main-container {
          //     padding: 5vw; /* Adjust padding for smaller screens */
          //   }
          // }
          .site-title {
            width: 80%;
            font-size: 3.5vw; /* Using vw units for font size */
            font-weight: 700;
            margin: 0;
            text-align: center;
            padding-bottom: 10px;
            // margin-left: 24px;
          }
          @media (max-width: 991px) {
            .site-title {
              font-size: 25px; /* Adjust font size for smaller screens */
            }
          }
          .guide-description .description-text {
            width: 55%;
            margin-top: 10px;
            font-size: 1.3vw; /* Using vw units for font size */
            font-weight: 400;
            text-align: center;
            line-height: 25px !important;
            // margin-bottom: 1vw;
          }
          .guide-description .access-btn {
            background-color: rgba(237, 237, 237, 1);

            width: 48%;
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 16px;
            font-weight: 700;
            margin-top: 40px;
            cursor: pointer;
            border: none;
          }
          @media (max-width: 600px) {
            .accss-btn {
              padding-top: 15px;
              padding-bottom: 15px;
              font-size: 2vw;
            }
            .site-title {
              width: 100%;
            }
          }
          @media (max-width: 991px) {
            .guide-description .access-btn {
              font-size: 4vw;
            }
            .guide-description .description-text {
              width: 70%;
              font-size: 16px;
              margin-bottom: 12px;
              line-height: 18px !important;
            }

            .footer-nav {
              margin-top: 20px;
            }
            .footer-nav .footer-link {
              color: #fff;
              text-decoration: none;
              margin-right: 1vw;
              font-size: 2.5vw;
              margin-top: 100px;
            }
          }
          .footer {
            margin-top: 6vw; /* Using vw units for margin */
            font-size: 1vw; /* Using vw units for font size */
            font-weight: 700;
            text-align: center;
          }
          .footer-nav .footer-link {
            color: #fff;
            text-decoration: none;
            margin-right: 16px;
            margin-top: 100px;
          }
          // @media (max-width: 991px) {
          //   .footer {
          //     margin-top: 6.5vw; /* Adjust margin for smaller screens */
          //   }
          // }
        `}</style>
      </footer>
    </>
  );
};

export default ShowsDetailPage;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginHeader from "../../components/LoginHeader/LoginHeader";
import { BASEURL } from "../../API/constants";
import Loader from "react-js-loader";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const payload = {
    username: email,
    password: password,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(payload),
  };

  const handleLogin = async () => {
    if (!email || !password) {
      setError("All fields are required.");
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}/auth/login`, options);
      const data = await response.json();
      
      console.log("Login Data", data);
      console.log("data?.data?.is_active", typeof data?.data?.is_active);
      if (!data.status) {
        setError(data.message);
        setLoading(false);
      } else if (data?.data?.status == "pending") {
        console.log("LOGIN DATA", data.data);
        console.log("pending payment")
        setLoading(false);
        setError("");
        setLoading(false);
        // localStorage.setItem(
        //   "UserToken",
        //   JSON.stringify(data.data.accessToken)
        // );
        navigate("/pricing-plan");
        // localStorage.setItem("UserInfo", JSON.stringify(data.data));
      }
      else if (data?.data?.status == "expired") {
        console.log("LOGIN DATA", data.data);
        setLoading(false);
        setError("");
        setLoading(false);
        navigate("/pricing-plan",{
          state:{
            data:data.data,token:data.data.accessToken
          }
        });
      }
      else if (data?.data?.is_active === 4) {
        console.log("LOGIN DATA", data.data);
        setLoading(false);
        setError("");
        setLoading(false);
        navigate("/pricing-plan",{
          state:{
            data:data.data,token:data.data.accessToken
          }
        });
      }
      else{
        localStorage.setItem(
          "UserToken",
          JSON.stringify(data.data.accessToken)
        );
        localStorage.setItem("UserInfo", JSON.stringify(data.data));
        navigate("/user-dashboard");
      }
    } catch (error) {
      console.log("Login Error: ", error);
    }
  };

  return (
    <>
      <LoginHeader />
      <div className="container mt-5 pb-5">
        <div className="row">
          <div className="col-lg-8">
            <h2 className="mb-5 text-white signup-heading">HI TRUFLIXER.</h2>
            <div className="form-group justify-content-flex">
              <div className="col-lg-2 email">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="email"
                >
                  Email
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
             
                  type="email"
                  variant="outlined"
                  className="form-control login-input-trueflix"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    style: {
                      border: "none",
                      outline: "none",
                      color:"white"
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div
              className="form-group justify-content-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-lg-2 email">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="login-password"
                >
                  PASSWORD
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
                
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  className="form-control login-input-trueflix"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{marginRight:"10px"}}>
                        <IconButton
                          color="white"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          // sx={{ color: "white" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      border: "none",
                      outline: "none",
                      color:"white"
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            {error && <div className="text-danger mb-3">{error}</div>}
            <div className="text-white mb-3">
              <p
                style={{ padding: "10px", cursor: "pointer" }}
                onClick={() => navigate("/get-plan")}
              >
                Don't have an account?
              </p>
            </div>
            <div className="form-group mt-3">
              {loading ? (
                <Loader
                  type="spinner-default"
                  bgColor={"white"}
                  color={"black"}
                  title={""}
                  size={50}
                />
              ) : (
                <button
                  className="account-submit sign-up mt-3"
                  onClick={() => handleLogin()}
                  style={{ margin: "0" }}
                >
                  LOGIN
                </button>
              )}
            </div>
          </div>

          <div className="col-lg-4"></div>
        </div>
      </div>
      <style>{`
        .filter-input-container {
          border-radius: 5px;
        }

        .header {
          color: #fff;
          font-size: 2rem;
          font-weight: 700;
          line-height: 59px;
          text-align: left;
          padding-left: 9rem;
          padding-right: 5rem;
          padding-top: 2em;
          text-transform: uppercase;
          font-family: Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
        }

        .movies-container {
          display: flex;
          gap: 0.9em;
          padding: 2em;
          padding-left: 8rem;
          padding-right: 5rem;
          background: #000;
          width: 100%;
          overflow-x: auto;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }

        .movies-container::-webkit-scrollbar {
          display: none;
        }

        .movie-poster {
          flex: 0 0 auto;
          width: 100%;
          max-width: 195px;
          aspect-ratio: 0.68;
          object-fit: cover;
          cursor: pointer;
          margin-right: 1rem;
        }

        .poster-title {
          font-size: 7rem !important;
          margin-top: -48px;
          color: #333333;
          font-weight: 700;
          font-family: "Montserrat";
        }

        @media screen and (max-width: 768px) {
          .movies-container {
            padding: 1em;
          }
          .search-btn-movies {
            padding;
          }

          .header {
            font-size: 35px;
            line-height: 37px;
            padding: 60px 10px;
          }
          .movie-poster {
            max-width: 150px;
          }
        }
        @media screen and (max-width: 425px) {
          .header h1 {
            font-size: 16px;
          }
        }
        @media screen and (max-width: 400px) {
          .header h1 {
            font-size: 12px;
          }
        }
      `}</style>
    </>
  );
}

export default LoginPage;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";

import Suscriptionscreen from "./pages/Pricingplanpage/NoSuscriptionscreen";
import SuscriptionPlan from "./pages/SuscriptionPage/SuscriptionPlan";
import "/node_modules/bootstrap/dist/css/bootstrap.css";
import MovieList from "./pages/MovieListPage/Movielist";
import Accountcreation from "./pages/PricingForm/Accountcreation";

import AccessCodeEntryPage from "./pages/AccessCodeEntryPage/AccessCodeEntryPage";
import TerritoryConfirmationPage from "./pages/TerritoryConfirmationPage/TerritoryConfirmationPage";
import AccountCreationProcessPage from "./pages/AccountCreationProcessPage/AccountCreationProcessPage";
import ManadatoryReferencesAcknowledgementPage from "./pages/ManadatoryReferencesAcknowledgementPage/ManadatoryReferencesAcknowledgementPage";

import "/node_modules/bootstrap/dist/css/bootstrap.css";
import MoviesDetailsScreen from "./pages/MoviesDetailPage/MoviesDetailsScreen";
import ShowsList from "./pages/ShowsList/ShowsList";
import AcccountcreationPage from "./pages/AccountcreationPage/AcccountcreationSignupPage";
import MembershipConfirmationPage from "./pages/MembershipConfirmationPage/MembershipConfirmationPage";
import ReferralAccessCodeProvidedPage from "./pages/ReferralAccessCodeProvidedPage/ReferralAccessCodeProvidedPage";
import Test from "./pages/Test";
import EventHighLights from "./pages/EventHighlightPage/EventHighlightPage";
import Payment from "./pages/Payment";
import ShowsDetailsScreen from "./pages/ShowsDetailsPage/ShowsDetailsPage";
import ShowsDetailsPage from "./pages/ShowsDetailsPage/ShowsDetailsPage";
import EventHighlightPage from "./pages/EventHighlightPage/EventHighlightPage";
import EventsHighLights from "./components/EventsHighLights";
import LoginPage from "./pages/LoginPage/LoginPage";
import Subscriptionscreen from "./pages/Subscribe/Subscribe";
import MemberShipCancellationPage from "./pages/MembershipCancellation/MemberShipCancellation";
import ChangePasswordPage from "./pages/ChangePassword/ChangePassword";
import ReferredUsers from "./pages/ReferredUsers/ReferredUsers";
import WhatsAppConfirmation from "./pages/WhatsAppConfirmation.jsx/WhatsAppConfirmation";
import RegistrationEdit from "./pages/RegistrationEdit";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {/* subscription screen */}
      <Route path="/get-subscription" element={<Suscriptionscreen />} />

      {/* Movies list */}
      <Route path="/movie-list" element={<MovieList />} />
      {/* SHows list */}
      <Route path="/shows-list" element={<ShowsList />} />
      {/* User profile screen */}
      <Route path="/user-dashboard" element={<Accountcreation />} />
      {/* change password */}
      <Route path="/change-password" element={<ChangePasswordPage />}/>
      {/* test screen (used for misc) */}
      <Route path="/test" element={<Test />} />
      <Route path="/sign-up-edit" element={<RegistrationEdit />} />

      {/* country verification screen */}
      <Route path="/country-verification" element={<TerritoryConfirmationPage />} />

      {/* Referral screen */}
      <Route
        path="/mandatory"
        element={<ManadatoryReferencesAcknowledgementPage />}
      />
      {/* Membership confirmation screen */}
      <Route path="/subscription-success" element={<MembershipConfirmationPage />} />
      {/* Referrall code screen */}
      <Route path="/referral" element={<ReferralAccessCodeProvidedPage />} />
      {/* Movie details screen */}
      <Route path="/movies-details" element={<MoviesDetailsScreen />} />
      {/* Show details screen */}
      <Route path="/shows-details" element={<ShowsDetailsPage />} />
      {/* User registration screen */}
      <Route path="/account-creation" element={<AcccountcreationPage />} />
      {/* User Login Page */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/sign-up" element={<AcccountcreationPage />} />
      {/* Otp verification screen */}
      <Route path="/otp-verification" element={<AccountCreationProcessPage />} />
      {/* referral code  verification screen*/}
      <Route path="/access-code-verification" element={<AccessCodeEntryPage />} />
      {/* Plan showing scree */}
      <Route path="/pricing-plan" element={<SuscriptionPlan />} />

      {/* no subscription scree */}
      <Route path="/get-plan" element={<Subscriptionscreen />} />
      {/* Events screen */}
      <Route path="/event-highlights" element={<EventHighLights />} />
      <Route path="/events" element={<EventsHighLights />} />
      {/* cancellation page */}
      <Route path="/subscription-cancelled" element={<MemberShipCancellationPage />} />
      {/* payments */}

      <Route path="/payment" element={<Payment />} />
      <Route path="/confirm-whatsapp" element={<WhatsAppConfirmation />} />
      <Route path="/referred-users" element={<ReferredUsers />} />
    </Routes>
  );
}
export default App;

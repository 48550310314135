import React, { useEffect, useRef } from "react";
import "./HomePage.css";
import HomeHero1 from "../../components/HomeHero/HomeHero1";
import MovieHighLight from "../../components/MovieHighLight";
import SportsHighlights from "../../components/SportsHighlisghts";
import Header from "../../components/Header";
import EventHighLights from "../../components/EventsHighLights";
import StreamingGuide from "../../components/StreaminGuide";
import { useNavigation } from "react-router";
import airbender from "../../Assets/Images/images/airbender.png";
import oppenheimer from "../../Assets/Images/images/oppenheimer.png";
import revenge from "../../Assets/Images/images/revenge.png";
import barbie from "../../Assets/Images/images/barbie.png";
import angry from "../../Assets/Images/images/angry.png";
import afraid from "../../Assets/Images/images/afraid.png";
import ShowHighLight from "../../components/ShowsHighLight";
import HomeHeroUnPaid from "../../components/HomeHeroUnpaid";

const HomePage = () => {
  
  
  const homeHeroRef = useRef(null);
  const movieHighlightRef = useRef(null);
  const showHighlightRef = useRef(null);
  const eventHighlightsRef = useRef(null);
  const sportsHighlightsRef = useRef(null);
  const streamingGuideRef = useRef(null);
  let userInfo = localStorage.getItem("UserInfo");

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // useEffect(() => {
  //   const data = localStorage.getItem("UserInfo");
  //   if (data == null) {
  //     scrollToRef(streamingGuideRef);
  //   }
  // }, []);

  return (
    <>
      {/* <HomeHero1 />
      <MovieHighLight  showHeader={true} data={posters} />
      <ShowHighLight headerText="SHOWS THAT THEY CANNOT GET ENOUGH OF" showHeader={true} data={posters}/>
      <div style={{marginBottom:'200px'}}>
      <EventHighLights/>
      </div>
      <SportsHighlights /> 
      <StreamingGuide /> */}
      <div ref={homeHeroRef}>
        {userInfo == null ?<HomeHeroUnPaid/>
        :<HomeHero1/>}
      </div>
      <div data-aos="fade-up" ref={movieHighlightRef}>
        <MovieHighLight showHeader={true}  margin={"120px"} />
      </div>
      <div data-aos="fade-up" data-aos-duration="1000" ref={showHighlightRef}>
        <ShowHighLight
          headerText="SHOWS THAT THEY CANNOT GET ENOUGH OF"
          showHeader={true}
          
        />
      </div>
      <div data-aos="fade-up" data-aos-duration="2000" ref={eventHighlightsRef} style={{ marginBottom: "200px" }}>
        <EventHighLights />
      </div>
      {userInfo == null && (
      <>
      <div  ref={sportsHighlightsRef}>
        <SportsHighlights />
      </div>
      <div  ref={streamingGuideRef}>
        <StreamingGuide />
      </div>
      <div>
      
      </div>
     
      </>
      )}
      <footer className="footer">
        <div className="container" style={{
              borderTop: "0.5px solid gray",
              // backgroundColor: "white",
              marginBottom: "80px",
            }}>
          <div className="row mt-4" >
            <div className="col-lg-6">
              <div className="footer-logo tex-left">
                <img
                  src="/images/TRUFLIX-logo.svg"
                  alt="img"
                  className="logo-img"
                />
                <p className="text-white mt-2">
                  Truflix © 2024. All Rights Reserved..
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-dev">
                <nav className="footer-nav footer-link-sec ">
                  <ul className="footer-links">
                    <a href="#" className="footer-link p-0" style={{lineHeight:"5px"}} >
                      <li>TERMS AND CONDITIONS</li>
                    </a>
                    <a href="#" className="footer-link"  style={{lineHeight:"5px"}} >
                      <li>DISCLAIMER</li>
                    </a>
                    <a href="#" className="footer-link"  style={{lineHeight:"5px"}} >
                      <li>TRUFLIX PRIVATE POLICY</li>
                    </a>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <style jsx>{`
        .main-container {
          width: 100%;
          background-color: #000;
          color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 5vw 10vw; /* Using vw units for padding */
          font-family: Montserrat, sans-serif;
        }
        // .site-header {
        //   width: 100%;
        //   text-align:center
        // }
        // .site-header h1 {
        //   text-align:center;
        //   padding-top: 3em !important;

        // }
        .guide-description {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        // @media screen and (max-width: 1440px) {
        //   .site-header h1 {
        //     padding-top: 5em !important;
        //     padding-left: 1rem !important;
        //   }
        // }
        // @media (max-width: 991px) {
        //   .main-container {
        //     padding: 5vw; /* Adjust padding for smaller screens */
        //   }
        // }
        .site-title {
          width: 80%;
          font-size: 3.5vw; /* Using vw units for font size */
          font-weight: 700;
          margin: 0;
          text-align: center;
          padding-bottom: 10px;
          // margin-left: 24px;
        }
        @media (max-width: 991px) {
          .site-title {
            font-size: 25px; /* Adjust font size for smaller screens */
          }
        }
        .guide-description .description-text {
          width: 55%;
          margin-top: 10px;
          font-size: 1.3vw; /* Using vw units for font size */
          font-weight: 400;
          text-align: center;
          line-height: 25px !important;
          // margin-bottom: 1vw;
        }
        .guide-description .access-btn {
          background-color: rgba(237, 237, 237, 1);

          width: 48%;
          padding-top: 5px;
          padding-bottom: 5px;
          font-size: 16px;
          font-weight: 700;
          margin-top: 40px;
          cursor: pointer;
          border: none;
        }
        @media (max-width: 600px) {
          .accss-btn {
            padding-top: 15px;
            padding-bottom: 15px;
            font-size: 2vw;
          }
          .site-title {
            width: 100%;
          }
        }
        @media (max-width: 991px) {
          .guide-description .access-btn {
            font-size: 4vw;
          }
          .guide-description .description-text {
            width: 70%;
            font-size: 16px;
            margin-bottom: 12px;
            line-height: 18px !important;
          }

          .footer-nav {
            margin-top: 20px;
          }
          .footer-nav .footer-link {
            color: #fff;
            text-decoration: none;
            margin-right: 1vw;
            font-size: 2.5vw;
            margin-top: 100px;
          }
        }
        .footer {
          margin-top: 15vw; /* Using vw units for margin */
          font-size: 1vw; /* Using vw units for font size */
          font-weight: 700;
          text-align: center;
        }
        .footer-nav .footer-link {
          color: #fff;
          text-decoration: none;
          margin-right: 16px;
          margin-top: 100px;
        }
        // @media (max-width: 991px) {
        //   .footer {
        //     margin-top: 6.5vw; /* Adjust margin for smaller screens */
        //   }
        // }
      `}</style>
    </>
  );
};

export default HomePage;

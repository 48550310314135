import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useNavigation } from "react-router";
import { useNavigate } from "react-router-dom";
import airbender from "../Assets/Images/images/airbender.png";
import oppenheimer from "../Assets/Images/images/oppenheimer.png";
import revenge from "../Assets/Images/images/revenge.png";
import barbie from "../Assets/Images/images/barbie.png";
import angry from "../Assets/Images/images/angry.png";
import afraid from "../Assets/Images/images/afraid.png";
import { BASEURL } from "../API/constants";
import { Mode } from "@mui/icons-material";
import Loader from "react-js-loader";

const MoviePoster = ({ imageSrc, alt, imageNumber, id, }) => {
  const navigate = useNavigate()
  let data = localStorage.getItem("UserInfo");
  return(
    <>
    <p className="poster-title">{imageNumber}</p>
    <img
      onClick={()=>{
        if(data==null){
          navigate("/get-subscription")
        }else{
          navigate("/movies-details",{
            state:{
              movie_id:id
            }
          })
        }
        
      }}
      className="movie-poster"
      src={imageSrc}
      alt={alt}
      loading="lazy"
    />
  </>
  )
}

function MovieHighLight({ headerText, showHeader,margin }) {
  const posters = [
    {
      id: 1,
      src: airbender,
      alt: "Movie poster 1",
    },
    {
      id: 2,
      src: oppenheimer,
      alt: "Movie poster 2",
    },
    {
      id: 3,
      src: revenge,
      alt: "Movie poster 3",
    },
    {
      id: 4,
      src: barbie,
      alt: "Movie poster 4",
    },
    {
      id: 5,
      src: angry,
      alt: "Movie poster 5",
    },
    {
      id: 6,
      src: afraid,
      alt: "Movie poster 6",
    },
    {
      id: 7,
      src: afraid,
      alt: "Movie poster 6",
    },
    {
      id: 8,
      src: afraid,
      alt: "Movie poster 6",
    },
    {
      id: 9,
      src: afraid,
      alt: "Movie poster 6",
    },
    {
      id: 10,
      src: afraid,
      alt: "Movie poster 6",
    },
  ];
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  let data = localStorage.getItem("UserInfo");
  const navigate = useNavigate();
  const handleNavigate = () => {
    console.log("clicked")
  };

  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
      const response = await fetch(
          `${BASEURL}/entertainment/get-categorized/movies`,
          // {
          //   mode:"no-cors"
          // }
        );
        console.log(`${BASEURL}/entertainment/get-categorized/movies`)
        const data = await response.json();

        setMovies(data.data);
        console.log("data.data",data.data)
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error?.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid" style={{marginTop:margin}}>
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-2 ">
            {showHeader ? (
              <h2 className="header"  >
                MOVIES WHICH EVERYONE IS LOVING
              </h2>
            ) : null}
            {loading ? (
              <Loader type="spinner-default" bgColor={"white"} color={"white"} title={""} size={80} />
            ) : (
              <ScrollContainer className="movies-container">
                {movies[1]?.items?.map((poster, index) => (
                  <MoviePoster
                    handleClick={handleNavigate}
                    key={poster.id}
                    imageNumber={index + 1}
                    imageSrc={poster.poster_image}
                    alt={poster.alt}
                    id={poster.id}
                    navigate={navigate}
                    data={data}
                  />
                ))}
              </ScrollContainer>
            )}
          </div>
        </div>

        <style jsx>{`
    // *{
    //     background: #000;
    // }
    .header  {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 59px;
      text-align: left;
      padding-left:6rem;
      padding-right:5rem;
      // padding: 0.5em;
      padding-top: 2em;
      text-transform:uppercase;
      // margin-left:3em !important;
      font-family: Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
      z-index:2;
  }

      .movies-container {
        display: flex;
        gap: 1.5em;
        padding: 2em;
        padding-left:6rem;
        padding-right:5rem;
        background: #000;
        overflow-x: auto; /* Trigger scroll on overflow */
        scrollbar-width: none; /* Firefox scrollbar */
        -ms-overflow-style: none; /* IE scrollbar */
      }

      .movies-container::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Chrome, Safari, Edge */
      }

      .movie-poster {
        flex: 0 0 auto;
        width: 100%;
        max-width: 165px;
        aspect-ratio: 0.68;
        object-fit: cover;
        cursor:pointer;
      }
      .poster-title{
        font-size:3rem !important;
         margin-top:-20px;
        color:#333333;
        font-weight:700;
        font-family:"Montserrat";
      }


      // @media screen and (max-width: 1440px){
      //   .header h1{
      //     padding-top:5em !important;
      //     margin-left:3em !important;
      //   }
      // }
      @media screen and (max-width: 768px) {
        /* Adjustments for smaller screens */
        .movies-container {
          padding: 1em;
        }
        
        .header  {
          font-size: 35px;
          line-height: 37px;
          
         
          padding:60px 10px;
        }
        .movie-poster {
          max-width: 150px;
        }
      }
      @media screen and (max-width: 425px) {
        
        .header h1 {
          font-size: 16px;
        }
        @media screen and (max-width: 400px) {
          .header h1 {
            font-size: 12px;
          }

          // loading animation
          .loader-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
  
          .loader {
            border: 2px solid white;
            border-left: 8px solid #000;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            animation: spin 1s linear infinite;
          }
  
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }




    `}</style>
      </div>
    </>
  );
}

export default MovieHighLight;
